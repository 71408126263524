import React from "react"
import { graphql } from "gatsby"
import { CategoryMain } from "../styles/Layout"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import Breadcrumb from "../components/Breadcrumb"
import {
  HeroContent,
  FeaturedImage,
  LinkList,
  SectionTitle,
} from "../components/Category/CategoryElements"
import { LinkBtn } from "../styles/btn"

import {
  CardListWrapper,
  CardSection,
} from "../components/Category/PostCardElements"
import PostCard from "../components/Category/PostCard"
import Seo from "../components/seo"
import Newsletter from "../components/Newsletter"

const CategoryPage = ({ data }) => {
  const {
    slug,
    title,
    seoDescription,
    description,
    subCategories,
    featuredImage,
    article,
  } = data.category

  const image = getImage(featuredImage)
  const featuredImgSrc = getSrc(featuredImage)

  const sortedArticles =
    article &&
    article.sort((a, b) => {
      let da = new Date(a.publishDate)
      let db = new Date(b.publishDate)

      if (da > db) {
        return -1
      } else if (da === db) {
        return 0
      } else {
        return 1
      }
    })

  return (
    <>
      <Seo
        title={title}
        description={seoDescription}
        image={featuredImgSrc}
        imageAlt={featuredImage?.description || title}
        article={false}
      />

      <CategoryMain id="main-content">
        <FeaturedImage>
          <GatsbyImage
            image={image}
            alt={title}
            loading="eager"
            style={{
              height: "59vh",
              gridColumn: "1/-1",
              gridRow: "1/-1",
            }}
          />
          <Breadcrumb
            className={null}
            categoryTitle={title}
            categoryLink={slug}
            // subCategoryTitle={data.subCategory?.title}
            // subCategoryLink={`/${data.subCategory?.slug}`}
          />
          <HeroContent>
            <h1 className="bg-grad">{data.category?.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
          </HeroContent>
        </FeaturedImage>

        {subCategories && (
          <LinkList>
            {subCategories.map(subCategory => {
              return (
                <LinkBtn
                  key={subCategory.id}
                  to={`/${slug}/${subCategory.slug}`}
                >
                  {subCategory.title}
                </LinkBtn>
              )
            })}
          </LinkList>
        )}

        <CardSection>
          <SectionTitle>
            <span>/</span> Stories in <span>{title}</span> Category
          </SectionTitle>
          <CardListWrapper>
            {article &&
              sortedArticles.map(post => {
                return (
                  <PostCard
                    key={post.id}
                    title={post.title}
                    slug={`/${data.category.slug}/${post.subCategory?.slug}/${post.slug}`}
                    pubDate={post.publishDate}
                    validDate={post.validDate}
                    img={post.featuredImage}
                  />
                )
              })}
          </CardListWrapper>
        </CardSection>

        <Newsletter />
      </CategoryMain>
    </>
  )
}

export default CategoryPage

export const categoryQuery = graphql`
  query ($id: String!) {
    category: contentfulCategory(id: { eq: $id }) {
      id
      slug
      title
      seoDescription
      featuredImage {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.777777, quality: 80)
      }
      description {
        childMarkdownRemark {
          html
        }
      }

      subCategories {
        title
        slug
        id: contentful_id
      }

      article {
        id: contentful_id
        title
        slug
        publishDate(formatString: "MM/DD/YYYY")
        validDate: publishDate(formatString: "YYYY-MM-DD")
        category {
          title
          slug
        }
        subCategory {
          title
          slug
        }
        featuredImage {
          gatsbyImageData(
            quality: 50
            width: 140
            height: 140
            layout: CONSTRAINED
          )
        }
      }
    }
  }
`
